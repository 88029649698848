var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumbs}}),(_vm.editApi.isError)?_c('AError',{attrs:{"api":_vm.editApi,"callbackReset":function () { return (_vm.editApi.isError = false); }}}):_vm._e(),(_vm.countryApi.isError)?_c('AError',{attrs:{"api":_vm.countryApi,"callbackReset":function () { return (_vm.countryApi.isError = false); }}}):_vm._e(),(_vm.api.isError)?_c('AError',{attrs:{"api":_vm.api,"callbackReset":function () { return (_vm.api.isError = false); }}}):_vm._e(),_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"800","outlined":"","min-height":"200"}},[_c('v-card-title',[_vm._v("Change Mobile")]),_c('div',{staticClass:"d-flex align-center justify-space-around"},[(_vm.step == 1)?_c('div',{staticClass:"form-content text-center"},[_c('v-form',{ref:"formMobile",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center pt-3"},[_c('v-row',{staticClass:"px-0 pt-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.countries,"outlined":"","item-text":_vm.countryNamed,"item-value":"countryCode","loading":_vm.countryApi.isLoading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","max-width":"30","max-height":"40","src":require('@/assets/Flag/' + item.countryCode + '.svg')}})]}}],null,false,3016922407),model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"8"}},[_c('TextField',{attrs:{"prefix":_vm.selectedCountry && _vm.selectedCountry.phoneCode
                      ? _vm.selectedCountry.phoneCode
                      : null,"value":_vm.mobile,"label":"Mobile Number","rules":_vm.mobileRules,"type":"text"},on:{"input":function($event){_vm.mobile = $event}}})],1)],1)],1),_c('div',{staticClass:"text-center pt-1"},[_c('v-btn',{attrs:{"width":"75%","rounded":"","color":"primary","dark":"","loading":_vm.otpApi.isLoading},on:{"click":function($event){return _vm.sendOtp()}}},[_vm._v(" Next ")])],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{attrs:{"width":"75%","rounded":"","outlined":"","color":"primary","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])],1)])],1):_vm._e(),(_vm.step == 2)?_c('div',{staticClass:"form-content text-center"},[_c('v-form',{ref:"formOtp",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_vm._v(" We sent One Time Password (OTP) to "+_vm._s(_vm.selectedCountry.phoneCode)+" "+_vm._s(this.mobile)+". ")]),_c('div',{staticClass:"text-center pt-3"},[_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'OTP is required'; },
                function () { return Number.isInteger(Number(_vm.otp)) ||
                  'The OTP must be an integer number'; } ],"length":"6","block":"","label":"OTP","outlined":"","clearable":""},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1),_c('div',[_vm._v(" Didn't receive? "),(_vm.resendTime > 0)?_c('span',[_vm._v(" Wait "+_vm._s(_vm.resendTime)+" seconds.")]):_c('span',[_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.otpApi.isLoading},on:{"click":function($event){return _vm.reSendOtp()}}},[_vm._v(" Resend OTP ")])],1)]),_c('div',{staticClass:"text-center pt-1"},[_c('v-btn',{attrs:{"width":"75%","rounded":"","color":"primary","dark":"","loading":_vm.otpCheckApi.isLoading},on:{"click":function($event){return _vm.validateOtp()}}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{attrs:{"width":"75%","rounded":"","outlined":"","color":"primary","dark":""},on:{"click":function($event){return _vm.decrease()}}},[_vm._v(" Back ")])],1)])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }