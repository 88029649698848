<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <AError
      v-if="countryApi.isError"
      :api="countryApi"
      :callbackReset="() => (countryApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Change Mobile</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="form-content text-center">
          <v-form ref="formMobile" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <v-row class="px-0 pt-3">
                <v-col cols="4">
                  <v-select
                    :items="countries"
                    outlined
                    v-model="countryCode"
                    :item-text="countryNamed"
                    item-value="countryCode"
                    :loading="countryApi.isLoading"
                  >
                    <template v-slot:selection="{ item }">
                      <v-img
                        contain
                        max-width="30"
                        max-height="40"
                        :src="
                          require('@/assets/Flag/' + item.countryCode + '.svg')
                        "
                      ></v-img>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="mx-0 px-0" cols="8">
                  <TextField
                    :prefix="
                      selectedCountry && selectedCountry.phoneCode
                        ? selectedCountry.phoneCode
                        : null
                    "
                    :value="mobile"
                    label="Mobile Number"
                    :rules="mobileRules"
                    type="text"
                    @input="mobile = $event"
                  ></TextField>
                </v-col>
              </v-row>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                @click="sendOtp()"
                :loading="otpApi.isLoading"
              >
                Next
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-if="step == 2" class="form-content text-center">
          <v-form ref="formOtp" v-model="valid" lazy-validation>
            <div>
              We sent One Time Password (OTP) to
              {{ selectedCountry.phoneCode }} {{ this.mobile }}.
            </div>
            <div class="text-center pt-3">
              <v-text-field
                :rules="[
                  (v) => !!v || 'OTP is required',
                  () =>
                    Number.isInteger(Number(otp)) ||
                    'The OTP must be an integer number',
                ]"
                v-model="otp"
                length="6"
                block
                label="OTP"
                outlined
                clearable
              ></v-text-field>
            </div>
            <div>
              Didn't receive?
              <span v-if="resendTime > 0"> Wait {{ resendTime }} seconds.</span
              ><span v-else
                ><v-btn
                  color="primary"
                  text
                  @click="reSendOtp()"
                  :loading="otpApi.isLoading"
                >
                  Resend OTP
                </v-btn></span
              >
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="otpCheckApi.isLoading"
                @click="validateOtp()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextField from "../../components/Form/TextField.vue";
export default {
  components: {
    TextField,
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    step: 1,
    valid: true,
    mobile: null,
    otp: null,
    resendTime: 60,
    setInterval: null,
    selectedCountry: null,
    countries: [],
    countryCode: "MYS",
    mobileRules: [],
    data: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    countryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.updateBreadcrumb();
   

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/check";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      if(this.data.countryCode){
      this.countryCode = this.data.countryCode
      }
    this.$api.fetch(this.countryApi);
    };
    this.countryApi.method = "GET";
    this.countryApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/country/";

    this.countryApi.callbackReset = () => {
      this.countryApi.isLoading = true;
      this.countryApi.isError = false;
    };

    this.countryApi.callbackError = (e) => {
      this.countryApi.isLoading = false;
      this.countryApi.isError = true;
      this.countryApi.error = e;
    };

    this.countryApi.callbackSuccess = (resp) => {
      this.countryApi.isLoading = false;
      this.countries = resp;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    this.mobile = this.data.mobile.replace(this.selectedCountry.phoneCode,'');
    };
    this.otpApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/send";

    this.otpApi.callbackReset = () => {
      this.otpApi.isLoading = true;
      this.otpApi.isError = false;
    };

    this.otpApi.callbackError = (e) => {
      this.otpApi.isLoading = false;
      this.otpApi.isError = true;
      this.otpApi.error = e;
    };

    this.otpApi.callbackSuccess = () => {
      this.otpApi.isLoading = false;
      this.step = 2;
      this.resendTime = 60;
      this.startTime();
    };

    this.otpCheckApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/check";

    this.otpCheckApi.callbackReset = () => {
      this.otpCheckApi.isLoading = true;
      this.otpCheckApi.isError = false;
    };

    this.otpCheckApi.callbackError = (e) => {
      this.otpCheckApi.isLoading = false;
      this.editApi.isLoading = false;
      this.otpCheckApi.isError = true;
      this.otpCheckApi.error = e;
    };

    this.otpCheckApi.callbackSuccess = () => {
      this.isCheckedKey = true;
      this.submit();
    };
    //EOC
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/changeMobile";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.otpCheckApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.otpCheckApi.isLoading = false;
      this.$router.push({ name: "PageAccountMyAccount" });
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    resendTime: function () {
      if (this.resendTime < 1) {
        clearInterval(this.setInterval);
      }
    },
    countryCode: function (val) {
      this.countryCode = val;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "PageAccountMyAccount" });
    },
    updateBreadcrumb() {
      this.breadcrumbs.push({
        text: "My Account",
        to: { name: "PageAccountMyAccount" },
        exact: true,
      });

      this.breadcrumbs.push({
        text: "Change Mobile",
        to: {
          name: "PageAccountChangeMobile",
        },
        exact: true,
      });
    },

    submit() {
      this.editApi.params = {
        mobile: this.selectedCountry.phoneCode + this.mobile,
        countryCode: this.countryCode,
        method: this.data.method,
      };
      this.$api.fetch(this.editApi);
    },

    sendOtp() {
      //this.otpApi.callbackReset();
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formMobile.validate()) {
          self.otpApi.params = {
            mobile: self.selectedCountry.phoneCode + self.mobile,
            countryCode: self.countryCode,
            purpose: "account_register",
          };
          self.$api.fetch(self.otpApi);
        } else {
          self.otpApi.callbackError("Please check the form.");
        }
      });
    },
    reSendOtp() {
      this.otpApi.callbackReset();

      this.otpApi.params = {
        mobile: this.selectedCountry.phoneCode + this.mobile,
        countryCode: this.countryCode,
        purpose: "account_register",
      };
      this.$api.fetch(this.otpApi);
    },
    validateOtp() {
      if (this.$refs.formOtp.validate()) {
        this.otpCheckApi.params = {
          otp: this.otp,
          mobile: this.selectedCountry.phoneCode + this.mobile,
          purpose: "account_register",
        };
        this.$api.fetch(this.otpCheckApi);
      } else {
        this.otpApi.callbackError("Please check the form.");
      }
    },
    startTime() {
      this.setInterval = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime = this.resendTime - 1;
        }
      }, 1000);
    },
    countryNamed: (item) => `(${item.phoneCode}) ${item.name}`,
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>